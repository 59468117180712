import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  CallUs,
  BannerLinks,
  BannerSmall,
  ProjectPagination
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import { generateBreadcrumbsLinks } from '../../utils/index'
import contacts from '../../components/CallUs/DefaultContacts'

import BannerImage from '../../assets/header.wedl.jpg'
import image4 from '../../assets/bannerlinks/berufsreise.bannerbox.jpg'
import image5 from '../../assets/bannerlinks/bannerbox.tiwag.jpg'


const Wedl = () => {
  const links = generateBreadcrumbsLinks('/projekte/wedl', 'Wedl')
  return (
    <Layout margin={false} color="white">
      <Helmet
        title="Wedl geht neue Wege"
        meta={[
          {
            name: 'description',
            content:
              'Für das Wedl Handelshaus hat holzweg in Kooperation mit Digithaler eine neue Website umgesetzt. Der Fokus beim Relaunch lag auf einer kundenorientierten Website die sich durch emotionale Bildsprache und spannenden Inhalten präsentiert.'
          },
          {
            name: 'keywords',
            content:
              'Wedl holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Wedl, Wedl Handelshaus, Website, Digithaler'
          },
          {
            property: 'og:description',
            content:
              'Für das Wedl Handelshaus hat holzweg in Kooperation mit Digithaler eine neue Website umgesetzt. Der Fokus beim Relaunch lag auf einer kundenorientierten Website die sich durch emotionale Bildsprache und spannenden Inhalten präsentiert.'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/header.wedl-592dd01edda103253efbcbf4c4b4b90e.jpg'
          }
        ]}
      />
      <BannerSmall image={BannerImage} title="Multiportalsystem zum Genießen" />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            Wedl geht neue Wege!
          </div>
        }
        text={
          <span>
            <p>Für das Wedl Handelshaus hat holzweg in Kooperation mit Digithaler eine neue Website umgesetzt. Der Fokus beim Relaunch lag auf einer kundenorientierten Website die sich durch emotionale Bildsprache und spannenden Inhalten präsentiert.</p>
            <p>Neben den spannenden Produktwelten wurde auch ein Fokus auf Jobs gelegt. Jobsuchende finden schnell und einfach an welchen Standorten von Wedl offene Stellen angeboten werden. Außerdem sind Ansprechpartner und die unterschiedlichen Standorte von Wedl übersichtlich auf einer Karte zu finden.</p>
          </span>
        }
        buttons={[
          {link: 'https://www.wedl.com', text: 'Zur Website'},
        ]}
      />
      <CallUs contacts={contacts} title="Sie wollen mehr wissen?" />
      <ProjectPagination
        titleLeft={
          <span>Tiwag Wärmepumpen<br/>Animationen</span>
        }
        imageLeft={image5}
        leftLink="/projekte/tiwag-waermepumpe"
        titleRight="Berufsreise"
        imageRight={image4}
        rightLink="/projekte/berufsreise"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Wedl
